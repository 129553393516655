import type { IPicture } from '../../../types/pictures'
import type { Block } from '../../../types/block'
import { Picture } from '../../molecules/Picture'
import { theme } from '../../../styles/theme'

export interface IArticleImage extends Block {
  pictures?: IPicture
}

export function ArticleImage({ pictures, 'data-module': dataModule }: IArticleImage) {
  return (
    <>
      <Picture
        className="ArticleImage"
        data-module={dataModule}
        {...pictures}
        placeholderTheme="light"
        withFullScreen={true}
      />

      <style global jsx>{`
        .ArticleImage {
          padding: 0 ${theme.cssVars.marginLR} 50px;
          width: 100%;
          height: 100%;
        }

        .ArticleImage img {
          width: 100%;
          height: 100%;
        }

        .ArticleImage figcaption {
          font-weight: 300;
          font-size: 12px;
          line-height: 144%;
          color: var(--color-deepBlue);
        }
      `}</style>
    </>
  )
}
