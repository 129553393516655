import type { BlockData } from '../../templates/Block'
import { useCallback, useContext } from 'react'
import { TagCommanderContext } from '../../../contexts/tagcommander'
import { LinkArticle } from '../../atoms/LinkArticle'
import { ShowMoreButton } from '../../atoms/ShowMoreButton'
import { theme } from '../../../styles'

export interface IMoreLinkArticle {
  elementList: BlockData<'article-bounce-folder'>['elementList']
}

export function MoreLinkArticle({ elementList }: IMoreLinkArticle): JSX.Element {
  const { hit } = useContext(TagCommanderContext)
  const moreButtonLink = 'moreButtonLink'

  const handleClick = useCallback(
    (index, id) => {
      hit(
        {
          id: 'click-reco-article',
          reco_position: String(index + 1),
          recoArticle_clickedArticleId: id,
          screen_clickableElementName: 'bloc-sur-le-meme-theme',
          screen_position: `1,${String(index + 1)}`,
        },
        { isClickEvent: true },
      )
    },
    [hit],
  )

  return (
    <>
      <input type="checkbox" id={moreButtonLink} className="MoreLinkArticle__Input" />
      <ul className="MoreLinkArticle flex flex-column justify-center">
        {elementList?.map(({ title, link, date, id, type, hasMultiLive }, index) => (
          <li
            onClick={() => handleClick(index, id)}
            key={`${id}-${index}`}
            className={`MoreLinkArticle__Link flex${
              index > 4 ? ' MoreLinkArticle__Link__Hidden' : ''
            }`}
          >
            <LinkArticle
              date={date}
              title={title}
              link={link}
              target="_blank"
              type={type}
              hasMultiLive={hasMultiLive}
            />
          </li>
        ))}
      </ul>
      {elementList.length > 4 && (
        <ShowMoreButton id="MoreLinkArticle__ShowMoreButton" htmlFor={moreButtonLink} />
      )}
      <style jsx>{`
        .MoreLinkArticle {
          list-style: none;
          padding: 0;
          margin: 0 ${theme.block.marginLR}px 20px;
        }

        .MoreLinkArticle__Link:nth-last-child(1) {
          border: 0;
        }

        .MoreLinkArticle__Link {
          border-bottom-width: 1px;
          border-bottom-style: solid;
          border-color: ${theme.cssVars.lightGrayBlue};
          padding: 1em 0;
        }

        .MoreLinkArticle__Link :global(.LinkArticle__Text) {
          color: ${theme.cssVars.fontColor};
        }

        .MoreLinkArticle__Link:first-child {
          padding-top: 0;
        }

        .MoreLinkArticle__Link__Hidden,
        .MoreLinkArticle__Input {
          display: none;
          visibility: hidden;
        }

        .MoreLinkArticle__Input:checked ~ .MoreLinkArticle > .MoreLinkArticle__Link__Hidden {
          display: block;
          visibility: visible;
        }

        :global(#${moreButtonLink}:checked ~ #MoreLinkArticle__ShowMoreButton) {
          display: none;
          visibility: hidden;
        }
      `}</style>
    </>
  )
}
