import type { Block } from '../../../types/block'
import { memo, useContext, useEffect, useRef } from 'react'
import { useAmp } from 'next/amp'
import slugify from 'slugify'
import { PAGE_TYPE_CONTENT } from '../../../constants/page'
import { ApiResponseContext } from '../../../contexts/ApiResponseContext'
import { TagCommanderContext } from '../../../contexts/tagcommander'
import { isServer } from '../../../helpers/client'
import { mixins, theme } from '../../../styles'

export interface IParagraph extends Block {
  text: string
}

export const MIN_CHARACTER_LENGTH_CAPITALIZE = 100

export function getSeparatedContents(
  text: string,
  isAmp = false,
): {
  tag: string
  content: string
  selfClosing?: boolean
}[] {
  // Remove body tag
  const textWithoutBody =
    text?.match(/<body>(.*)<\/body>/gs)?.[0].replace(/<\/?body>/g, '') || text || ''

  // Separate first level tags and string without tags
  const htmlTagsContents: string[] =
    textWithoutBody?.match(
      /(<([a-z][a-z0-9]*)\b[^>]*\/>)|(<([a-z][a-z0-9]*)\b[^>]*>(.*?)<\/\4>|[^<>]+)/gis,
    ) || []

  // Group tags by type
  return htmlTagsContents
    .map((htmlTagContent) => {
      // Handle empty content
      if (!htmlTagContent || htmlTagContent.trim() === '') {
        return null
      }

      // Extract tag
      const htmlMatches = htmlTagContent?.match(/<([a-z][a-z0-9]*)\b([^>]*)>/i)
      const tag = htmlMatches?.[1] || ''
      const hasAttributes = !!htmlMatches?.[2]?.match?.(/[\w-]+(?:=".+?"|\b)/gm)?.length
      const htmlTagContentFiltred = isAmp
        ? htmlTagContent.replace(/<iframe/g, '<amp-iframe').replace(/<\/iframe/, '</amp-iframe')
        : htmlTagContent

      if (tag === 'iframe') {
        return {
          tag: 'div',
          content: htmlTagContentFiltred,
        }
      }

      if (hasAttributes) {
        return {
          tag: 'div',
          content: htmlTagContentFiltred,
        }
      }

      if (htmlTagContentFiltred === 'br' || htmlTagContentFiltred === 'br ') {
        return {
          tag: 'br',
          content: '',
          selfClosing: true,
        }
      }

      // Handle content without tag
      if (!tag) {
        return {
          tag: 'p',
          content: htmlTagContentFiltred,
        }
      }

      const isSelfClosingTag = !!htmlTagContentFiltred?.match(/<([a-z][a-z0-9]*)\b[^>]*\/>/i)?.[1]

      if (isSelfClosingTag) {
        return {
          tag,
          content: '',
          selfClosing: true,
        }
      }

      // Extract content
      const content =
        htmlTagContentFiltred?.match(/<([a-z][a-z0-9]*)\b[^>]*>(.*?)<\/\1>/is)?.[2] || ''

      if (!content) {
        return null
      }

      return {
        tag,
        content,
      }
    })
    .filter(Boolean)
}

function ParagraphComponent({ text, 'data-module': dataModule }: IParagraph): JSX.Element {
  const isAmp = useAmp()
  const elementsRef = useRef<HTMLElement[]>([])
  const { type: pageType } = useContext(ApiResponseContext)
  const { hit } = useContext(TagCommanderContext)

  // Split text into paragraphs
  const contents = getSeparatedContents(text, isAmp)

  const handleLinkClick = (e: MouseEvent): void => {
    const value = e.target as HTMLAnchorElement
    const linkText = value.textContent
    hit(
      {
        screen_clickableElementName: `lien-article_${slugify(linkText, { lower: true })}`,
      },
      { isClickEvent: true },
    )
  }

  useEffect(() => {
    if (!isServer() && pageType === PAGE_TYPE_CONTENT) {
      const links = elementsRef.current.map((ref) => ref?.querySelectorAll?.('a')) || []
      links.map((elementLinks) =>
        elementLinks.forEach((link: HTMLAnchorElement) => {
          link?.addEventListener('touchstart', handleLinkClick, { passive: true })
          link?.addEventListener('mousedown', handleLinkClick, { passive: true })
        }),
      )

      return () => {
        links.map((elementLinks) =>
          elementLinks.forEach((link: HTMLAnchorElement) => {
            link?.removeEventListener('touchstart', handleLinkClick)
            link?.removeEventListener('mousedown', handleLinkClick)
          }),
        )
      }
    }
  }, [text])

  return (
    <>
      {contents.map(({ tag, content, selfClosing }, index) => {
        const Element = tag

        if (selfClosing) {
          return <Element key={index} />
        }

        return (
          <Element
            key={index}
            data-module={dataModule}
            // eslint-disable-next-line
            // @ts-ignore
            className={`Paragraph${index === contents.length - 1 ? ' Paragraph__End' : ''}`}
            ref={(ref) => (elementsRef.current[index] = ref)}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        )
      })}

      <style global jsx>{`
        .Paragraph {
          margin: 0 ${theme.block.marginLR}px 20px;
          color: ${theme.cssVars.deepBlue};
          font-family: ${theme.fonts.overpass};
          font-style: normal;
          font-weight: 300;
          font-size: 20px;
          word-break: break-word;
        }

        .Paragraph > * {
          scroll-margin-top: 85px;
        }

        .Paragraph__End {
          margin: 0 ${theme.block.marginLR}px 30px;
        }

        .Paragraph:last-child {
          margin-bottom: 50px;
        }

        ul.Paragraph {
          padding-left: 20px;
        }

        .Paragraph a {
          color: ${theme.cssVars.focusBlue};
          text-decoration: underline;
          text-underline-offset: 0.2em;
        }

        .Paragraph li {
          margin-bottom: 20px;
        }

        ol.Paragraph {
          margin: 0;
          padding: 0 0 0 75px;
          list-style-position: outside;
        }

        ol.Paragraph li {
          padding: 0;
        }

        ol.Paragraph li::marker {
          content: counter(list-item) '  ';
          color: ${theme.cssVars.deepBlue};
          font-size: 32px;
          font-style: italic;
          font-weight: 700;
          line-height: 120%;
        }

        :where(h1, h2).Paragraph {
          font-weight: 800;
        }

        :where(h1, h2, h3, h4).Paragraph {
          margin: 0 ${theme.block.marginLR}px 20px;
        }

        h1.Paragraph {
          font-size: 32px;
        }

        h2.Paragraph,
        h3.Paragraph {
          font-size: 20px;
        }

        h3.Paragraph {
          text-decoration: underline;
        }

        h4.Paragraph {
          font-size: 20px;
          font-style: italic;
        }

        @media ${mixins.mediaQuery.tabletPaysage} {
          h1.Paragraph {
            font-size: 48px;
          }

          h2.Paragraph,
          h3.Paragraph {
            font-size: 25px;
          }

          h4.Paragraph {
            font-size: 24px;
          }
        }

        @media ${mixins.mediaQuery.tablet} {
          .Paragraph__End {
            margin: 0 ${theme.block.marginLR}px 40px;
          }

          ol.Paragraph li::marker {
            font-size: 38px;
          }
        }

        @media ${mixins.mediaQuery.desktop} {
          .Paragraph {
            font-size: 22px;
            line-height: 153%;
          }
        }
      `}</style>
    </>
  )
}

export const Paragraph = memo(ParagraphComponent)
